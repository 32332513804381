import React from "react";

// core components
import LandingPageHeader from "./LandingPageHeader.js";
import LandingHeaderText from "./HeaderText";
import MainFooter from "./MainFooter.js";


function LandingPage() {
  
  return (
    <>
      <LandingPageHeader />
      { window.innerWidth<768 && <LandingHeaderText/>}
      <MainFooter/>
    </>
  );
}

export default LandingPage;
