import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss?v=1.2.0";
import "./index.css";

// pages
// import LandingPage from "views/home-page/LandingPage.js";
// import AboutUsPage from "views/about-us/AboutUsPage.js";
import UnderDev from "views/under-dev/UnderDev.js";
import PrivacyPolicy from  "views/privacy-policy/PrivacyPolicy";
//import BlogPage from "views/blog/BlogPage.js";
//import PaymentPage from "views/payment/PaymentPage";
// others

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route
        path="/home"
        render={(props) => <UnderDev {...props} />}
      />
      <Route
        path="/privacy-policy"
        render={(props) => <PrivacyPolicy {...props} />}
      />
      {/* <Route
        path="/home"
        render={(props) => <LandingPage {...props} />}
      />
      <Route
        path="/about"
        render={(props) => <AboutUsPage {...props} />}
      /> */}
      {/*
      <Route
      path="/blog"
      render={(props) => <BlogPage {...props} />}
      />
      <Route
      path="/payment"
      render={(props) => <PaymentPage {...props} />}
      /> */}
      <Redirect to="/home"/>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
